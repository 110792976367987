@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

.pipeline-name {
  color: awsui.$color-text-button-normal-default !important;
}

.pipeline-status-indicator {
  float: right;
  text-align: left;
  width: 100px;
}

.pipeline-update-message {
  white-space: pre-line;
}

.pipeline-updates-content {
  text-align: center !important;
}
