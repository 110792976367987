@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

.pomodoro-timer {
}

.timer-toggle {
  text-align: center !important;
  margin: 0 auto !important;
}

.timer-task-input {
  text-align: center !important;
  align-content: center !important;
  margin: 0 auto !important;
  width: 80%;
}

@font-face {
  font-family: "Orbitron";
  src: url("../../../assets/Orbitron-VariableFont_wght.ttf") format("truetype");
}

.timer-label {
  color: awsui.$color-text-button-normal-default;
  font-weight: bold;
  text-align: center;
  font-family: "Orbitron", "Amazon Ember", "Helvetica Neue", Roboto, Arial,
    sans-serif;
  font-size: 55px;
  padding: 25px;
}

.timer-button {
  text-align: center !important;
  align-content: center !important;
  margin: 0 auto !important;
  width: 80%;
  padding-top: 20px;
}

.restart-button {
  text-align: center !important;
  align-content: center !important;
  margin: 0 auto !important;
}
