@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

.account-name {
  color: awsui.$color-text-button-normal-default;
  font-weight: bold;
  font-stretch: condensed;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 0;
}

.account-id {
  color: awsui.$color-charts-status-neutral !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 14px;
  margin-top: 0;
}
