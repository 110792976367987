@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

.custom-top-bar-header {
  padding: 30px 30px;
  text-align: center;
}

.custom-widget-box {
}

.search-amazon-form > div {
  vertical-align: top !important;
  margin-top: 0 !important;
}

.custom-widget-container {
  height: 400px;
  overflow: auto !important;
}

.edit-widgets-cursor {
  cursor: grab !important;
}

.edit-widgets-toggle > label > div > input {
  cursor: pointer !important;
}

.custom-home-text-inverted {
  color: awsui.$color-text-home-header-default;
}

.custom-home-text-secondary {
  color: awsui.$color-text-home-header-secondary;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -6rem;
  }
}

.custom-home-image__placeholder:before {
  content: "X";
  display: block;
  background-color: #f2f3f3;
  color: #ffffff;
  text-align: center;
  font-size: 40rem;
  line-height: 40rem;
}
