@use "~@amzn/awsui-design-tokens/polaris.scss" as awsui;

.clock-time {
  color: awsui.$color-text-button-normal-default;
  font-weight: bold;
  font-stretch: condensed;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 20px;
  margin-bottom: 0;
}

.clock-city {
  color: awsui.$color-charts-status-neutral;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 14px;
  margin-top: 0;
}
